@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
/* @font-face {
  font-family: "Indie"; 
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("IndieFlower-Regular"),
    url("./fonts/IndieFlower-Regular.ttf") format("truetype");
} */
 @font-face {
  font-family: "Itim-Regular"; 
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  letter-spacing: -1px;
  src: local("Itim-Regular"),
    url("./fonts/Itim-Regular.ttf") format("truetype");
}
a:link {
  color: #fff!important;
}
 a.blogItem-link {
  color: #000!important;
}
a > p {
  color: #3BC77E !important;
}
* {
  -webkit-locale: auto;
  white-space: normal;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
}
.drop a:link, .drop a {
  color: #9a9b9d !important;
  font-weight: 400;
}
.drop a:link:hover, .drop a:hover {
  color: #757575 !important;
  font-weight: 400;
}


h1, h2 {
  font-family: 'Itim';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  font-feature-settings: "kern";
  backface-visibility: hidden;
  text-anchor: middle;
}
p {
    font-size: 20px;
}
.nav-inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
}
blockquote{
  font-size: 1.4em;
  margin:10px auto;
  font-style:italic;
  color: #555555;
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid #3bc77e ;
  line-height:1.6;
  position: relative;
  background:#f1f1f1;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  border-radius: 0.5rem;
}

blockquote::before{
  font-family: "Georgia", serif;
  content: "\201C";
  color:#3bc77e;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}

blockquote span{
  display:block;
  color:#333333;
  font-style: normal;
  font-weight: bold;
  margin-top:1em;
}
/* .nav {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
nav.sticky {
  box-shadow: 0px 5px 10px rgba(0,0,0,0.6);
  position: sticky;
  top: 0;
  z-index: 100;
} */
.links a {
color: #fff;
font-size: 20px;
margin-right: 30px;
cursor: pointer;
}
.card{
  margin: 25px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border: 1px solid #fff;
  box-shadow: 0 0 5px #ddd;
  cursor: pointer;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
}

.cardHeader{
  height: 200px;
}

.cardHeaderImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card figcaption {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 10px 10px;
}
.card figcaption div {
  border: 1px solid white;
  border-radius: 32px;
  background: #3bc77e;
}
.card figcaption div h4 {
  display: block;
    font-weight: 700;
    padding: 5px 10px;
    color: #ffffff;
}

figure img{
    -webkit-transition: transform 0.5s linear !important;
            transition: transform 0.5s linear !important;
    -webkit-transform: scale3d(1, 1, 1) !important;
            transform: scale3d(1, 1, 1) !important;
            object-fit: cover;
  }
  figure{
    
    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border: 1px solid #fff;
    box-shadow: 0 0 5px #ddd;
    cursor: pointer;
  }
figure:hover img{
    -webkit-transform: scale3d(1.1, 1.1, 1) !important;
            transform: scale3d(1.1, 1.1, 1) !important;
  }
  #krugic:checked ~ .control-me::after {
    content: "😃";
}
.control-me {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 50px;
}
#krugic, #bone, #heart {
    position: absolute;
    right: 0; 
    display: none;
}
.maleSliciceInput {
    position: absolute;
    right: 0; 
    display: none;
}
.check {
    margin: -30px 0 0 -30px;
    stroke-dasharray: 140;
    stroke-dashoffset: 140;
    animation: fill 1s linear normal;
    animation-fill-mode: forwards;
    position: absolute;
    right: -20px;
    top: 10px;
    overflow: visible;
    z-index: 2;
}
 
.show {
  display: block;
}
li {
  transition: 0.5s all ease-in-out;
}
@keyframes fill {
    from {
        stroke-dashoffset: 140;
    } 
    to {
        stroke-dashoffset: 0;
    }
}
.svgholder svg {
    width: 100px;
}
.bs {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.cuteImg {
    margin-top: -20px;
    border: 1px solid lightgrey;
    padding: 3px;
    border-radius: 3px;
    height: 50px;
    width: auto;
}
.imgHolder {
    width: 100%;
    height: auto;
    position: absolute;
    top: -226px;
    left: 193px;
}

.rad,
.ckb{
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}
.rad > input,
.ckb > input{ /* HIDE ORG RADIO & CHECKBOX */
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
/* RADIO & CHECKBOX STYLES */
/* DEFAULT <i> STYLE */
.rad > i,
.ckb > i{ 
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  transition: 0.2s;
  box-shadow: inset 0 0 0 8px #fff;
  border: 1px solid gray;
  background: gray;
}
.rad > i {
  width:  16px;
}
.ckb > i {
  width: 25px;
  border-radius: 3px;
}
.rad:hover > i{ /* HOVER <i> STYLE */
  box-shadow: inset 0 0 0 3px #fff;
  background: gray;
}
.rad > input:focus + i { /* FOCUS <i> STYLE */
  outline: none;
}
.rad > input:checked + i{ /* (RADIO CHECKED) <i> STYLE */
  box-shadow: inset 0 0 0 3px #fff;
  background: orange;
  outline: none;
}
/* CHECKBOX */
.ckb > input + i::after{
  content: "";
  display: block;
  height: 12px;
  width:  12px;
  margin: 2px;
  border-radius: inherit;
  transition: inherit;
  background: gray;
}
.ckb > input:focus + i {
  outline: none;
}
.ckb > input:checked + i::after{ /* (RADIO CHECKED) <i> STYLE */
  margin-left: 11px;
  background:  orange;
}


.sivaPozadina {
  background-image: url(../src/assets/sivaPozadina.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */
}
.crvenaPozadina {
  background-image: url(../src/assets/crvenaPozadina.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */
}

.banner {
  position: absolute;
    top: 584px;
    width: 480px;
    right: 0;
}
.par {
  position: absolute;
    top: 584px;
    width: 480px;
    right: 0;
}
hr { 
  background: url(../src/assets/phone-icon.png) no-repeat top center;
    background-size: contain;
    display: block;
    height: 42px;
    border: 0;
    position: relative;
    margin: auto;
    width: 80%;
    margin-top: 40px;
}
hr:before,
hr:after {
  content: '';
  display: block;
  position: absolute;
  background: #2ec478;
  height: 3px;
  top: 18px;
}
hr:before {
  left: 0;
  right: 52%;
  margin-right: 20px;
}
hr:after {
  right: 0;
  left: 52%;
  margin-left: 20px;
}
.rnc {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 255px;
  background-color: #eee;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
}

.rnc-row {
  display: flex;
  align-items: stretch;
  margin: 0 0 10px 0;
}

.rnc-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 0 10px;
}

.rnc-canvas {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  width: 200px;
    height: 50px;
    font-size: 35px;
    text-align: center;
    font-family: serif;
}

.rnc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: #fff;
  color: inherit;
  border: none;
  padding: 0;
  width: 25px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 4px;
}

.rnc-button svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.rnc-input {
  border: none;
  padding: 10px 6px;
  font-size: inherit;
  font-family: inherit;
}

@keyframes inAnimation {
  0% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    height: 124px;
    opacity: 1;
    visibility: visible;
  }
}
/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #969696; 
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #525252; 
}
.toaster-bg {
  background: #fff!important;
}

.container {
  width: 90%;
  max-width: 1260px;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  text-align: center;
}

@import url(https://fonts.googleapis.com/css?family=Raleway:400,700);
figure.snip1426 {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  background-color: #f5f5f5;
  border-radius: 20px;
  margin-bottom: 25px;
}
@media only screen and (min-width: 600px) {
  figure.snip1426 {
    margin-bottom: 0;
    margin: 25px;
  }
}
figure.snip1426 *,
figure.snip1426 *:before,
figure.snip1426 *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.55s ease;
  transition: all 0.55s ease;
}
figure.snip1426 img {
  max-width: 100%;
  backface-visibility: hidden;
  vertical-align: top;
}
figure.snip1426 figcaption {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px 10px 10px;
}
/* figure.snip1426 figcaption:before,
figure.snip1426 figcaption:after {
  height: 2px;
  width: 400px;
  position: absolute;
  content: '';
  background-color: #989898;
} */
figure.snip1426 figcaption div {
  border: 1px solid white;
    border-radius: 32px;
    background: #3bc77e;
}
figure.snip1426 figcaption:before {
  top: -5px;
  left: 10px;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
figure.snip1426 figcaption:after {
  bottom: 0;
  right: 10px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
/* figure.snip1426 figcaption div:before,
figure.snip1426 figcaption div:after {
  width: 2px;
  height: 300px;
  position: absolute;
  content: '';
  background-color: #989898;
} */
figure.snip1426 figcaption div:before {
  top: 10px;
  right: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
figure.snip1426 figcaption div:after {
  bottom: 10px;
  left: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
figure.snip1426 h2,
figure.snip1426 h4 {
  margin: 0;
  text-transform: uppercase;
}
figure.snip1426 h2 {
  font-weight: 400;
}
figure.snip1426 h4 {
  display: block;
  font-weight: 700;
  padding: 5px 10px;
  color: #ffffff;
}
figure.snip1426 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
/* figure.snip1426:hover img,
figure.snip1426.hover img {
  zoom: 1;
  filter: alpha(opacity=50);
  -webkit-opacity: 0.5;
  opacity: 0.5;
} */
figure.snip1426:hover figcaption:before,
figure.snip1426.hover figcaption:before,
figure.snip1426:hover figcaption:after,
figure.snip1426.hover figcaption:after,
figure.snip1426:hover figcaption div:before,
figure.snip1426.hover figcaption div:before,
figure.snip1426:hover figcaption div:after,
figure.snip1426.hover figcaption div:after {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
figure.snip1426:hover figcaption:before,
figure.snip1426.hover figcaption:before,
figure.snip1426:hover figcaption:after,
figure.snip1426.hover figcaption:after {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}
.truncate4lines {
  overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.4rem;
    max-height: 8rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
}
.floating {  
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 5px;
  width: 100px;
  height: 52px!important;
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }    
}
.blog-goBack {
  text-decoration: none;
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
  margin-bottom: 2rem;
  display: block;
}
.blog-wrap header {
  text-align: center;
}

.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
}

.blog-wrap img {
  width: 100%;
}

.blog-subCategory {
  display: flex;
  justify-content: center;
}
.blog-subCategory > div {
  margin: 5px;
}
.blog-desc {
  padding: 1rem;
  margin-top: 1.5rem;
}
.blogItem-wrap {
  display: flex;
  flex-direction: column;
  padding: 25px;
}
.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}
.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1;
  font-size: 25px;
  font-weight: 600;
}
.blogItem-desc {
  position: relative;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 1rem;
  color: #a9a9a9;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
}

.blogItem-link {
  text-decoration: none;
  color: inherit;
}

.blogItem-author {
  display: flex;
  align-items: center;
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author p {
  font-size: 0.6rem;
  color: #a9a9a9;
  font-weight: 600;
}

.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  padding: 20px;
}

@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
.chip {
  font-size: 0.7rem;
  background: #3BC77E;
  color: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  width: fit-content;
  text-transform: capitalize;
}
.emptyList-wrap {
  display: flex;
  justify-content: center;
}
.emptyList-wrap img {
  max-width: 250px;
  width: 100%;
}
@keyframes loaderSwipeAnim{
  0%{
      left: -100%;
  }
  100%{
      left: 100%;
  }
}
:root {
  --ticket-height: 60px;
  --color-blue: lightblue;
  --color-orange: salmon;
  --color-green: greenyellow;
}
.section-prices {
  padding: 1.5rem 1rem;
}

.list-boxs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.list-boxs .card {
  width: 32%;
  text-align: center;
  position: relative;
  max-width: 300px;
}

.list-boxs .card .head,
.list-boxs .card .body {
  background-color: #FFF;
  padding: 1.5rem 1rem;
}

.list-boxs .card .head {
  color: #333;
  font-size: 1.2rem;
  text-transform: uppercase;
  transform: translateY(30px);
  transition: transform .3s linear;
  border-bottom: 1px solid #F2F2F2;
}
.list-boxs .card:hover .head {
  transform: translateY(0);
  box-shadow: 0 -5px 10px rgba(0, 0, 0, .1);
}

.list-boxs .card .ticket {
  color: #FFF;
  width: 80%;
  margin: 0 auto;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  height: var(--ticket-height);
  height: 200px;
  box-shadow: inset 0px 6px 6px -5px rgba(0, 0, 0, .25),
              inset 0px -6px 6px -5px rgba(0, 0, 0, .25);
}
.list-boxs .starter .ticket {
  background-color: var(--color-green);
}
.list-boxs .standard .ticket {
  background-color: var(--color-blue);
}
.list-boxs .premium .ticket {
  background-color: var(--color-orange);
}

.list-boxs .card .body {
  transform: translateY(-160px);
  transition: transform .3s linear;
}
.list-boxs .card:hover .body {
  transform: translateY(0);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
}

.list-boxs .card p {
  font-size: .95rem;
  color: #333;
  text-align: justify;
}
.list-boxs .card .btn {
  margin-top: 2rem;
  padding: .6rem 1rem;
  outline: none;
  font-size: .85rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  min-width: 160px;
  border: 1px solid #555;
  background-color: #555;
  color: #333;
  cursor: pointer;
  transition: all .2s linear;
}
.list-boxs .card .btn:hover {
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, .15);
}

.list-boxs .starter .btn {
  background: var(--color-green);
  border-color: var(--color-green);
}
.list-boxs .standard .btn {
  background: var(--color-blue);
  border-color: var(--color-blue);
}
.list-boxs .premium .btn {
  background: var(--color-orange);
  border-color: var(--color-orange);
}


@media screen and (max-width: 750px) {
  .list-boxs {
    justify-content: space-around;
  }
  .list-boxs .card {
    width: 45%;
  }
}

@media screen and (max-width: 550px) {
  .list-boxs .card {
    width: 95%;
  }
}
.scene {
  display: inline-block;
  width: 200px;
  height: 260px;
/*   border: 1px solid #CCC; */
  margin: 40px 0;
  perspective: 600px;
}

/* .cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
} */

.card1 {
  width: 300px;
  position: relative;
  cursor: pointer;
  /* transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s; */
}
.card1 p {
  font-size: 18px;
  margin-top: 300px;
}
.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 300px;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform-origin: center;
  transition: transform 1s;
}

.card-front {
  transform: rotateY(0deg);
}

.card-back {
  transform: rotateY(180deg);
}

.flipped .card-front {
  transform: rotateY(-180deg);
}

.flipped .card-back {
  transform: rotateY(0deg);
}

.card-front img {
  height: 100%;
  width: auto;
  object-fit: cover;
}


.card-front button {
  margin-top: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #333;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.card-back p {
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
}

.card2 {
	display: flex;
	flex-direction: column;
	width: 300px;
	flex-shrink: 0;
	flex-grow: 0;
	max-width: 100%;
	background-color: #FFF;
	box-shadow: 0 5px 10px 0 rgb(0 0 0 / 15%);
	border-radius: 10px;
	overflow: hidden;
	margin: 1rem;
}

.card2-img {
  width: 100%;
}
.card2-img.skeleton {
  height: 210px;
}
	.card2-img.skeleton img {
    position: absolute;
		width: 100%;
	}
.card2-body {
	padding-top: 0.5rem;
}
.card2-title {
	font-size: 1.25rem;
	line-height: 1.33;
	font-weight: 700;
}
.card2-title.skeleton { 
		min-height: 28px;
		border-radius: 4px;
	}
.card2-intro {
	margin-top: .75rem;
	line-height: 1.5;
}
.card2-intro.skeleton { 
		min-height: 72px;
		border-radius: 4px;
	}
.skeleton {
	background-color: #e2e5e7;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
	background-size: 40px 100%;
	background-repeat: no-repeat;
	background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}
@keyframes shine {
	to {
		background-position: right -40px top 0;
	}
}
.udomi-btn {
  outline: none;
    width: 110px;
    text-align: center;
    display: inline-block;
    border: none;
    padding: 10px 5px;
    cursor: pointer;
    border-radius: 6px;
    background: #3bc77e;   
    color: #fff;
    position: relative;
    top: 0;
    transition: 0.2s ease;
    margin-top: 20px;
}
.udomi-btn h2 {
  font-family: 'Itim';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    font-feature-settings: "kern";
    backface-visibility: hidden;
    text-anchor: middle;
    font-size: 23px;
}
.udomi-btn2 {
  outline: none;
    width: 110px;
    text-align: center;
    display: inline-block;
    border: none;
    padding: 10px 5px;
    cursor: pointer;
    border-radius: 6px;
    background: #ff543c;   
    color: #fff;
    position: relative;
    top: 0;
    transition: 0.2s ease;
    margin-top: 20px;
}
.udomi-btn2 h2 {
  font-family: 'Itim';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    font-feature-settings: "kern";
    backface-visibility: hidden;
    text-anchor: middle;
    font-size: 23px;
}

.date {
  font-size: 13px;
    font-weight: bold;
    color: white;
    background-color: rgba(51, 51, 51, 0.4);
    width: 44px;
    height: 44px;
    border-radius: 100%;
    padding: 1px;
    line-height: 18px;
    position: absolute;
    top: 1px;
    right: 1px;
    text-align: center;
    cursor: default;
    border: 1px solid #bdbdbd;
}
.date1 {
  font-size: 13px;
    font-weight: bold;
    color: white;
    background-color: rgba(51, 51, 51, 0.4);
    width: 44px;
    height: 44px;
    border-radius: 100%;
    padding: 1px;
    line-height: 18px;
    position: absolute;
    top: 1px;
    right: 1px;
    text-align: center;
    cursor: default;
    border: 1px solid #bdbdbd;
}

.date:hover {
  color: #3bc77e;
  background-color: #fff;
  cursor: default;
}
.date1:hover {
  color: #ff543c;
  background-color: #fff;
  cursor: default;
}
.udomi-ime{
  font-size: 23px;
  font-weight: bold;
  color: white;
  background-color: rgba(51, 51, 51, 0.4);
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 6px;
  text-align: center;
  position: absolute;
  transition: 0.8s;
  border: 2px dashed #fff;
  left: 5px;
  bottom: 5px;
  cursor: default;
  height: 51px;
}

.udomi-ime:hover {
  color: #3bc77e;
  background-color: #fff;
  cursor: default;
}
.udomi-ime1{
  font-size: 23px;
  font-weight: bold;
  color: white;
  background-color: rgba(51, 51, 51, 0.4);
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 6px;
  text-align: center;
  position: absolute;
  transition: 0.8s;
  border: 2px dashed #fff;
  left: 5px;
  bottom: 5px;
  cursor: default;
  height: 51px;
}

.udomi-ime1:hover {
  color: #ff543c;
  background-color: #fff;
  cursor: default;
}
.stats {
  display: flex;
  justify-content: space-between;
}
.profile-default .profile__stats {
  margin: 5px 25px;
}
.profile__stats__title {
  color: #505050;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
}
h5 {
  font: 500 18px/1.2 "Poppins", sans-serif;
  margin-bottom: 10px;
}
.udomi-span-ne {
  background-color: #ff543c;
    color: #fff;
    padding: 3px 7px;
    font-size: 17px;
    border-radius: 100%;
    
    margin-left: 10px;
}
.udomi-span-da {
  background-color: #3bc77e;
    color: #fff;
    padding: 3px 7px;
    font-size: 17px;
    border-radius: 100%;
    
    margin-left: 17px;
}
.udomi-span-da1 {
  background-color: #3bc77e;
    color: #fff;
    padding: 3px 7px;
    font-size: 17px;
    border-radius: 100%;
    
    margin-left: 24px;
}
.udomi-span-da2 {
  background-color: #3bc77e;
    color: #fff;
    padding: 3px 7px;
    font-size: 17px;
    border-radius: 100%;
    
    margin-left: 39px;
}
.udomi-span-ne1 {
  background-color: #3bc77e;
    color: #fff;
    padding: 3px 7px;
    font-size: 17px;
    border-radius: 100%;
    
    margin-left: 24px;
}
.udomi-span-ne2 {
  background-color: #3bc77e;
    color: #fff;
    padding: 3px 7px;
    font-size: 17px;
    border-radius: 100%;
    
    margin-left: 39px;
}
.udomi-p {
  font-weight: 700;
  font-size: 15px;
  color: #3bc77e;
}
.smallSub {
  top: 36px;
  color: #a19f9f;
  width: 100%;
}
.heart {
  fill: red;
  position: absolute;
  top: 5px;
  width: 50px;
  animation: pulse 1s ease infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

h5.title {
  margin: 0;
}
.slider-modal {
  display: none;
  opacity: 0;
  border-top: 2px solid #083;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1) translateZ(0);
  max-width: 575px;
  width: 100%;
  background: #fff;
  z-index: 9001;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.4), 0px 0px 0px 3000px rgba(0,0,0,0.25);
}
.slider-modal.modal-active {
  display: block;
  animation-name: modal-enter;
  animation-duration: 0.3s;
  animation-iterations: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.slider-modal.modal-close {
  animation-name: modal-leave;
  animation-duration: 0.2s;
  animation-iterations: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.slider-modal .title {
  font-size: 16px;
  padding: 20px;
  font-weight: normal;
  color: #929292;
}
.slider-modal .slides {
  position: relative;
  overflow: hidden;
  height: 300px;
  width: 100%;
}
.slider-modal .slide {
  position: absolute;
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center center;
  display: inline-block;
  z-index: 1;
}
.slider-modal .slide.active {
  z-index: 3;
}
.slider-modal .slider-image-caption {
  text-align: center;
  font-size: 14px;
  color: #777;
}
.slider-modal .close-icon {
  position: absolute;
  right: 10px;
  top: 8px;
  background: transparent;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.2s;
  cursor: pointer;
}
.slider-modal .close-icon:before,
.slider-modal .close-icon:after {
  position: absolute;
  content: "";
  height: 20px;
  width: 2px;
  background: #d00;
  left: 50%;
  top: 50%;
  margin-top: calc(20px / -2);
  margin-left: calc(2px / -2);
}
.slider-modal .close-icon:before {
  transform: rotate(45deg);
}
.slider-modal .close-icon:after {
  transform: rotate(-45deg);
}
.slider-modal .close-icon:active {
  background: rgba(0,0,0,0.1);
}
.slider-modal .nav-arrow {
  opacity: 0.3;
  position: absolute;
  background: #fff;
  height: 30px;
  width: 30px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
  border-radius: 50%;
  z-index: 10;
  top: 50%;
  margin-top: -15px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.slider-modal .nav-arrow:hover {
  opacity: 0.8;
}
.slider-modal .nav-arrow:before,
.slider-modal .nav-arrow:after {
  content: "";
  width: 2px;
  height: 10px;
  background: #000;
  position: absolute;
  left: 9px;
  top: 10px;
}
.slider-modal .nav-arrow:before {
  transform: rotate(50deg) translateY(-50%);
}
.slider-modal .nav-arrow:after {
  transform: rotate(-50deg) translateY(50%);
}
.slider-modal .nav-arrow.right {
  right: 20px;
  transform: rotate(180deg);
}
.slider-modal .nav-arrow.left {
  left: 20px;
}
@-moz-keyframes modal-enter {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  70% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@-webkit-keyframes modal-enter {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  70% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@-o-keyframes modal-enter {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  70% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes modal-enter {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  70% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
@-moz-keyframes modal-leave {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
}
@-webkit-keyframes modal-leave {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
}
@-o-keyframes modal-leave {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
}
@keyframes modal-leave {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
}
.CSSgal {
	position: relative;
	overflow: hidden;
	height: 100%; /* Or set a fixed height */
}

/* SLIDER */

.CSSgal .slider {
	height: 100%;
	white-space: nowrap;
	font-size: 0;
	transition: 0.8s;
}

/* SLIDES */

.CSSgal .slider > * {
	font-size: 1rem;
	display: inline-block;
	white-space: normal;
	vertical-align: top;
	height: 100%;
	width: 100%;
	background: none 50% no-repeat;
	background-size: cover;
}

/* PREV/NEXT, CONTAINERS & ANCHORS */

.CSSgal .prevNext {
	position: absolute;
	z-index: 1;
	top: 50%;
	width: 100%;
	height: 0;
}

.CSSgal .prevNext > div+div {
	visibility: hidden; 
}

.CSSgal .prevNext a {
	background: #fff;
	position: absolute;
	width:       60px;
	height:      60px;
	line-height: 48px;
	text-align: center;
	opacity: 0.7;
	-webkit-transition: 0.3s;
					transition: 0.3s;
	-webkit-transform: translateY(-50%);
					transform: translateY(-50%);
	left: 0;
}
.CSSgal .prevNext a:hover {
	opacity: 1;
}
.CSSgal .prevNext a+a {
	left: auto;
	right: 0;
}

/* NAVIGATION */

.CSSgal .bullets {
	position: absolute;
	z-index: 2;
	bottom: 0;
	padding: 10px 0;
	width: 100%;
	text-align: center;
}
.CSSgal .bullets > a {
	display: inline-block;
	width:       30px;
	height:      30px;
	line-height: 30px;
	text-decoration: none;
	text-align: center;
	background: rgba(255, 255, 255, 1);
	-webkit-transition: 0.3s;
					transition: 0.3s;
}
.CSSgal .bullets > a+a {
	background: rgba(255, 255, 255, 0.5); /* Dim all but first */
}
.CSSgal .bullets > a:hover {
	background: rgba(255, 255, 255, 0.7) !important;
}

/* NAVIGATION BUTTONS */
/* ALL: */
.CSSgal >s:target ~ .bullets >* {      background: rgba(255, 255, 255, 0.5);}
/* ACTIVE */
#s1:target ~ .bullets >*:nth-child(1) {background: rgba(255, 255, 255,   1);}
#s2:target ~ .bullets >*:nth-child(2) {background: rgba(255, 255, 255,   1);}
#s3:target ~ .bullets >*:nth-child(3) {background: rgba(255, 255, 255,   1);}
#s4:target ~ .bullets >*:nth-child(4) {background: rgba(255, 255, 255,   1);}
/* More slides? Add here more rules */

/* PREV/NEXT CONTAINERS VISIBILITY */
/* ALL: */
.CSSgal >s:target ~ .prevNext >* {      visibility: hidden;}
/* ACTIVE: */
#s1:target ~ .prevNext >*:nth-child(1) {visibility: visible;}
#s2:target ~ .prevNext >*:nth-child(2) {visibility: visible;}
#s3:target ~ .prevNext >*:nth-child(3) {visibility: visible;}
#s4:target ~ .prevNext >*:nth-child(4) {visibility: visible;}
/* More slides? Add here more rules */

/* SLIDER ANIMATION POSITIONS */

#s1:target ~ .slider {transform: translateX(   0%); -webkit-transform: translateX(   0%);}
#s2:target ~ .slider {transform: translateX(-100%); -webkit-transform: translateX(-100%);}
#s3:target ~ .slider {transform: translateX(-200%); -webkit-transform: translateX(-200%);}
#s4:target ~ .slider {transform: translateX(-300%); -webkit-transform: translateX(-300%);}
/* More slides? Add here more rules */


/* YOU'RE THE DESIGNER! 
   ____________________
   All above was mainly to get it working :)
   CSSgal CUSTOM STYLES / OVERRIDES HERE: */

.CSSgal{
	color: #fff;	
	text-align: center;
}
.CSSgal .slider h2 {
	margin-top: 40vh;
	font-weight: 200;
	letter-spacing: -0.06em;
	word-spacing: 0.2em;
	font-size: 3em;
}
.CSSgal a {
	border-radius: 50%;
	margin: 0 3px;
	color: rgba(0,0,0,0.8);
	text-decoration: none;
}
.prev-slide {
  font-size: 50px;
  width: 49%;
  text-align: left;
  left: 0;
}
.next-slide {
  font-size: 50px;
  width: 49%;
  text-align: right;
  right: 0;
}
.flex-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
font-weight:bold;
}

.flex-container > div {
padding-top:10px;
}
.paginate-ctn{
    display: flex;
    margin-top: 40px;
}
.round-effect{
  color: #ff553e;
    cursor: pointer;
    /* border: 1px solid red; */
    font-size: 16px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 9px;
    height: 40px;
    margin: 5px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    text-decoration: none;
    -webkit-user-select: none;
    user-select: none;
}

.round-effect:hover{
  text-decoration: none;
  background:#ff553e;
  color:#FFF;
}
.round-effect:hover a {
  text-decoration: none;
  color:#FFF;
}

.round-effect .active{
  background:#ff553e;
  color:#FFF;
}
.round-effect a:link {
  text-decoration: none;
  color:#DD4124;
}
.input-box {
  position: relative;
  width: 350px;
}
.input-box button {
  background: #ff543c;
    color: #fff;
    padding: 11px;
    font-family: 'Itim';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    font-feature-settings: "kern";
    backface-visibility: hidden;
    text-anchor: middle;
    font-size: 18px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.input-box input {
  position: relative;
  padding: 8px 10px;
  width: 100%;
  border: none;
  border-radius: 6px;
  outline: none;
  background: transparent;
  /* color: #fff; */
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
  z-index: 2;
  font-weight: 700;
}

.input-box span {
  position: absolute;
  left: 0;
  padding: 10px 0;
  pointer-events: none;
  font-size: 1rem;
  color: #a19f9f;
  transition: 0.5s;
}

.input-box input:valid ~ span,
.input-box input:focus ~ span {
  font-size: 0.85rem;
  transform: translateY(-32px);
}

.input-box i {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 1.5px;
  background: linear-gradient(45deg, #3bc77e, #3bc77e);
  transition: 0.5s;
  z-index: 1;
  pointer-events: none;
}
.input-box i.prazanInput {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    border-radius: 1.5px;
    transition: 0.5s;
    z-index: 1;
    pointer-events: none;
    border: 1px solid #3bc77e;
    background: transparent!important;
}

.input-box input:valid ~ i,
.input-box input:focus ~ i {
  height: 100%;
  border-radius: 6px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}
.boje_oblici {
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
}

#uplatnica {
  height: 95mm;
  width: 208mm;
  margin-right: auto;
  margin-left: auto;
  border: thin solid #CCC;
  text-align: left;
  margin-top: 30px;
}

#uplatilac {
  border: thin solid #333;
  float: left;
  height: 15mm;
  width: 91mm;
}

#uplatnica #levo {
  float: left;
  width: 98mm;
  height: 60mm;
  margin-top: 9mm;
  margin-left: 5mm;
  border-right-width: thin;
  border-right-style: solid;
  border-right-color: #999;
  font-size: 12px;
}

#svrhauplatenaslov {
  width: 91mm;
}

#svrhauplate {
  float: left;
  height: 15mm;
  width: 91mm;
  border: thin solid #333;
}

#primalac {
  float: left;
  height: 15mm;
  width: 91mm;
  border: thin solid #333;
}

#uplatnica #desno {
  width: 280px;
    float: right;
    height: 264px;
    }

#uplatnica #desno #naslov {
  text-transform: uppercase;
    font-weight: bolder;
    float: right;
    margin-right: 15px;
    margin-top: 0;
    font-size: 12px;
    }

#desno #racunprimaoca {
  height: 10mm;
  width: 92mm;
  margin-left: 9mm;
  margin-top: 3mm;
}

#desnakolona #kolonaiznos {
  width: 92mm;
  height: 13mm;
  margin-top: 8mm;
  margin-left: 9mm;
}

#desno #modelipozivnabr {
  width: 93mm;
  height: 13mm;
  margin-left: 9mm;
  margin-top: 3mm;
}

#desnakolona #kolonaiznos #kolonaiznosnaslov {
  height: 7mm;
  font-size: 12px;
}
#desnakolona {
  margin-left: -126px;
}

#kolonaiznos #kolonaiznosnaslov #sifraplacanjanaslov {
  width: 12mm;
  height: 7mm;
  float: left;
  font-size: 12px;
  margin-top: -10px;
}

#kolonaiznos #kolonaiznosnaslov #valutanaslov {
  width: 13mm;
  height: 4mm;
  float: left;
  margin-left: 5mm;
  margin-top: 8px;
}

#kolonaiznos #kolonaiznosnaslov #iznosnaslov {
  width: 53mm;
  float: left;
  height: 4mm;
  margin-left: 6mm;
  margin-top: 8px;
}

#desnakolona #kolonaiznos #kolonaiznosunos #sifraplacanjaunos {
  float: left;
  height: 8mm;
  width: 12mm;
  border: 2px solid #333;
}

#desnakolona #kolonaiznos #kolonaiznosunos #valutaunos {
  width: 12mm;
  float: left;
  height: 8mm;
  margin-left: 4mm;
  border: 2px solid #333;
  text-transform: uppercase;
  text-align: center;
}

#desnakolona #kolonaiznos #kolonaiznosunos #iznosunos {
  height: 8mm;
  width: 58mm;
  float: left;
  border: 2px solid #333;
  position: relative;
  margin-left: 5mm;
}

#desno #racunprimaoca {
  height: 10mm;
  width: 92mm;
  margin-left: 9mm;
  margin-top: 3mm;
}

#desno #racunprimaoca #racunprimaocanaslov {
  font-size: 12px;
  width: 99%;
}

#desno #racunprimaoca #racunprimaocaunos {
  height: 8mm;
  width: 99%;
  border: 2px solid #333;
}

#desno #modelipozivnabr {
  width: 93mm;
  height: 13mm;
  margin-left: 9mm;
  margin-top: 3mm;
}

#desno #modelipozivnabr #modelipozivbrnaslov {
  font-size: 12px;
}

#desno #modelipozivnabr #modelipozivunos {
  height: auto;
  width: 100%;
}

#desno #modelipozivnabr #modelipozivunos #modelunos {
  float: left;
  height: 8mm;
  width: 12mm;
  border: 2px solid #333;
}

#desno #modelipozivnabr #modelipozivunos #pozivbrunos {
  margin-left: 17mm;
  height: 8mm;
  width: 74mm;
  border: 2px solid #333;
}

#uplatnica #footer {
  width: 100%;
  margin-top: 70mm;
  height: 25mm;
  padding-left: 5mm;
  padding-top: 7mm;
}

#footer #pecatpotpis {
  width: 55mm;
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: #333;
  font-size: 12px;
}

#footer #mestounos {
  width: 45mm;
  margin-left: 75mm;
  padding-left: 10px;
  margin-top: -5px;
}

#footer #mestodatum {
  width: 50mm;
  font-size: 12px;
  margin-left: 75mm;
  margin-top: 1mm;
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: #333;
}

#footer #datumvalute {
  width: 35mm;
  margin-left: 140mm;
  border-top-width: thin;
  border-top-style: solid;
  border-top-color: #333;
  margin-top: -18px;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  #uplatnica {
    display: flex;
    flex-direction: column;
    height: 167mm;
    width: 75mm;
    margin-right: auto;
    margin-left: auto;
    border: none;
    text-align: left;
    margin-top: 30px;
  }
  #uplatnica #levo {
    float: none;
    width: 70mm;
    height: 60mm;
    margin-top: 9mm;
    margin-left: 3mm;
    border: none;
    font-size: 12px;
}
  #uplatilac {
    border: thin solid #333;
    float: left;
    height: 15mm;
    width: 70mm;
  }
  #svrhauplatenaslov {
    width: 70mm;
  }
  #svrhauplate {
    float: left;
    height: 15mm;
    width: 70mm;
    border: thin solid #333;
  }
  #primalac {
    float: left;
    height: 15mm;
    width: 70mm;
    border: thin solid #333;
  }

  #uplatnica #desno {
    width: 280px;
    float: none;
    height: 200px;
  }
  #uplatnica #desno #naslov {
    text-transform: uppercase;
    font-weight: bolder;
    float: left;
    margin-left: 14px;
    margin-top: -250px;
    font-size: 12px;
  }
  #desnakolona {
    margin-left: -25px;
  }
  #desnakolona #kolonaiznos {
    width: 71mm;
    height: 13mm;
    margin-top: 8mm;
    margin-left: 9mm;
  }
  #desnakolona #kolonaiznos #kolonaiznosnaslov {
    height: 7mm;
    font-size: 12px;
  }
  #kolonaiznos #kolonaiznosnaslov #sifraplacanjanaslov {
    width: 12mm;
    height: 7mm;
    float: left;
    font-size: 12px;
    margin-top: -10px;
  }
  #kolonaiznos #kolonaiznosnaslov #valutanaslov {
    width: 13mm;
    height: 4mm;
    float: left;
    margin-left: 5mm;
    margin-top: 8px;
  }
  #kolonaiznos #kolonaiznosnaslov #iznosnaslov {
    width: 35mm;
    float: left;
    height: 4mm;
    margin-left: 6mm;
    margin-top: 8px;
  }
  #desnakolona #kolonaiznos #kolonaiznosunos #sifraplacanjaunos {
    float: left;
    height: 8mm;
    width: 12mm;
    border: 2px solid #333;
  }
  #desnakolona #kolonaiznos #kolonaiznosunos #valutaunos {
    width: 12mm;
    float: left;
    height: 8mm;
    margin-left: 4mm;
    border: 2px solid #333;
    text-transform: uppercase;
    text-align: center;
  }
  #desnakolona #kolonaiznos #kolonaiznosunos #iznosunos {
    height: 8mm;
    width: 38mm;
    float: left;
    border: 2px solid #333;
    position: relative;
    margin-left: 5mm;
  }
  #desno #racunprimaoca {
    height: 10mm;
    width: 72mm;
    margin-left: 9mm;
    margin-top: 3mm;
  }
  #desno #racunprimaoca #racunprimaocanaslov {
    font-size: 12px;
    width: 99%;
  }
  #desno #racunprimaoca #racunprimaocaunos {
    height: 8mm;
    width: 99%;
    border: 2px solid #333;
  }
  #desno #modelipozivnabr {
    width: 72mm;
    height: 13mm;
    margin-left: 9mm;
    margin-top: 3mm;
  }
  #desno #modelipozivnabr #modelipozivbrnaslov {
    font-size: 12px;
  }
  #desno #modelipozivnabr #modelipozivunos {
    height: auto;
    width: 100%;
  }
  #desno #modelipozivnabr #modelipozivunos #modelunos {
    float: left;
    height: 8mm;
    width: 12mm;
    border: 2px solid #333;
  }
  #desno #modelipozivnabr #modelipozivunos #pozivbrunos {
    margin-left: 17mm;
    height: 8mm;
    width: 54mm;
    border: 2px solid #333;
  }
  #uplatnica #footer {
    width: 74mm;
    margin-top: 2mm;
    height: 25mm;
    padding-left: 3mm;
    padding-top: 7mm;
    display: flex;
    flex-direction: column;
  }
  #footer #pecatpotpis {
    width: 100%;
    border-top-width: thin;
    border-top-style: solid;
    border-top-color: #333;
    font-size: 12px;
  }
  #footer #mestounos {
    width: 45mm;
    margin-left: 75mm;
    padding-left: 10px;
    margin-top: -5px;
  }
  #footer #mestodatum {
    width: 100%;
    font-size: 12px;
    margin-left: 0;
    margin-top: 7mm;
    border-top-width: thin;
    border-top-style: solid;
    border-top-color: #333;
  }
  #footer #datumvalute {
    width: 100%;
    margin-left: 0mm;
    border-top-width: thin;
    border-top-style: solid;
    border-top-color: #333;
    margin-top: 19px;
    font-size: 12px;
  }
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}


 
